import login from "./login";
import register from "./register";
import company from "./company";
import user from "./user";

export default {
  login,
  register,
  company,
  user,
};
