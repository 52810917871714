import axios from "../instance";

export default {
    getAll: (warehouseId) => { // return axios.api.get(`/customer?warehouseId=${warehouseId}`).then((response) => response.data);
        return axios.api.get(`/zones`).then((response) => response.data);
    },
    getOne: (id) => {
        return axios.api.get(`/zones/${id}`).then((response) => response.data);
    },
    search: (body) => {
        return axios.api.post("/zones/search", body).then((response) => response.data);
    },
    create: (body) => {
        return axios.api.post("/zones", body).then((response) => response.data);
    },
    update: (id, body) => {
        return axios.api.put(`/zones/${id}`, body).then((response) => response.data);
    },
    delete: (id) => {
        return axios.api.delete(`/zones/${id}`).then((response) => response.data);
    }
};
