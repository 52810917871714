import axios from "../instance";

export default {
  getAll: () => {
    return axios.api.get("/users").then((response) => response.data);
  },

  getOne: (id) => {
    return axios.api.get(`/users/${id}`).then((response) => response.data);
  },

  update: (id, body) => {
    return axios.api
      .put(`/users/${id}`, body)
      .then((response) => response.data);
  },
};
