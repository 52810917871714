import axios from "../instance";

export default {
    getAll: () => { // return axios.api.get(`/customer?warehouseId=${warehouseId}`).then((response) => response.data);
        return axios.api.get(`/customers`).then((response) => response.data);
    },
    getCustomerByRouteId: (id) => { // return axios.api.get(`/customer?warehouseId=${warehouseId}`).then((response) => response.data);
        return axios.api.get(`/customers?routeId=${id}`).then((response) => response.data);
    },
    getAllbyRouteId: (routeId) => { 
        return axios.api.get(`/customers?routeId=${routeId}`).then((response) => response.data);
        // return axios.api.get(`/routes`).then((response) => response.data);
    },
    getOne: (id) => {
        return axios.api.get(`/customers/${id}`).then((response) => response.data);
    },
    search: (body) => {
        return axios.api.post("/customers/search", body).then((response) => response.data);
    },
    create: (body) => {
        return axios.api.post("/customers", body).then((response) => response.data);
    },
    update: (id, body) => {
        return axios.api.put(`/customers/${id}`, body).then((response) => response.data);
    },
    delete: (id) => {
        return axios.api.delete(`/customers/${id}`).then((response) => response.data);
    }
};
