<template>
  <div class="mb-3" @keydown.enter="onSearched">
    <div class="d-flex justify-content-start">
      <div class="searchbar">
        <input
          v-model="searchText"
          class="search_input"
          type="text"
          name=""
          placeholder="Search..."
        />
        <a @click="onSearched" class="search_icon"
          ><i style="font-size: 18px" class="fas fa-search"></i
        ></a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    searchText: "",
  }),
  methods: {
    onSearched() {
      this.$emit("textSearch", this.searchText);
    },
  },
};
</script>

<style scoped>
.searchbar {
  margin-bottom: auto;
  margin-top: auto;
  height: 60px;
  background-color: #e8f1f5;
  border-radius: 8px;
  padding: 9.5px;
}

.search_input {
  color: black;
  border: 0;
  outline: 0;
  background: none;
  width: 0;
  caret-color: transparent;
  line-height: 40px;
  transition: width 0.4s linear;
  font-size: 18px;
}

.searchbar:hover > .search_input {
  padding: 0 10px;
  width: 350px;
  caret-color: black;
  transition: width 0.4s linear;
}

@media only screen and (max-width: 600px) {
  .searchbar:hover > .search_input {
    padding: 0 10px;
    width: 80%;
    caret-color: black;
    transition: width 0.4s linear;
  }
}
@media only screen and (max-width: 300px) {
  .searchbar:hover > .search_input {
    padding: 0 10px;
    width: 50%;
    caret-color: black;
    transition: width 0.4s linear;
  }
}

.searchbar:hover > .search_icon {
  background: white;
  color: white;
}

.search_icon {
  height: 40px;
  width: 40px;
  float: right;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: white;
  text-decoration: none;
  cursor: pointer;
}
</style>
