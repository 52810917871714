import axios from "../instance";

export default {
    getAll: (warehouseId) => { // return axios.api.get(`/customer?warehouseId=${warehouseId}`).then((response) => response.data);
        return axios.api.get(`/discounts`).then((response) => response.data);
    },
    getOne: (id) => {
        return axios.api.get(`/discounts/${id}`).then((response) => response.data);
    },
    search: (body) => {
        return axios.api.post("/discounts/search", body).then((response) => response.data);
    },
    create: (body) => {
        return axios.api.post("/discounts", body).then((response) => response.data);
    },
    update: (id, body) => {
        return axios.api.put(`/discounts/${id}`, body).then((response) => response.data);
    },
    delete: (id) => {
        return axios.api.delete(`/discounts/${id}`).then((response) => response.data);
    }
};
